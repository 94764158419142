import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import { addInventorySchema } from "../validationSchemas";
import { addInventoryApi, editInventoryApi, getInventoryApi } from "../../store/inventory";
import { showToast } from "../../toast/toast";
import { set } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  bgLightGray: {
    backgroundColor: "rgba(213, 222, 229, 1)",
  },
  borderRadius10: {
    borderRadius: "10px",
  },
  p10: {
    padding: "10px",
  },
  p20: {
    padding: "20px",
  },
  buttonsStyle: {
    backgroundColor: "#D7EDFE",
  },
  fullWidth: {
    width: "100%",
  },
  marginBottom20: {
    marginBottom: "20px",
  },
  inputGroup: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
  },
  imageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed #ccc",
    padding: "20px",
    borderRadius: "10px",
    cursor: "pointer",
    position: "relative",
  },
  imageUploadText: {
    marginTop: "10px",
    color: "#888",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  previewImage: {
    maxWidth: "100%",
    maxHeight: "200px",
    borderRadius: "10px",
  },
  deleteIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "#fff",
    borderRadius: "50%",
    cursor: "pointer",
  },
}));

const AddInventory = ({ open, handleClose, editRowData }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const supplierList = useSelector((state) => state.organizationReducer?.result);
  const categoryList = useSelector((state) => state.categoryReducer?.result);
  const classes = useStyles();
  const inputRef = useRef(null);
  const [inputValues, setInputValues] = useState();
  const [filePreview, setFilePreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleInputChangeQuantity = (e) => {
    const { name, value } = e.target;
    const validNumber = /^[0-9]*$/;
    if (validNumber.test(value)) {
        setInputValues({
            ...inputValues,
            [name]: value
        });
    } else {
        // Optionally, you can keep the existing value instead of clearing it.
        setInputValues({
            ...inputValues,
            [name]: inputValues[name] || ""
        });
    }
  }
  const handleInputChangePrice = (e) => {
    const { name, value } = e.target;
    const validNumber = /^[0-9]*\.?[0-9]{0,2}$/;

    if (validNumber.test(value)) {
        setInputValues({
            ...inputValues,
            [name]: value
        });
    } else {
        // Optionally, you can keep the existing value instead of clearing it.
        setInputValues({
            ...inputValues,
            [name]: inputValues[name] || ""
        });
    }
};

const [imageError, setImageError] = useState("");

const handleFileChange = (e) => {
  setImageError("");
  const file = e.target.files[0];

  if (file) {
    // Check if the file is a jpeg/jpg image
    const validImageTypes = ['image/jpeg', 'image/jpg'];
    if (validImageTypes.includes(file.type)) {
      setInputValues({ ...inputValues, snap: file });
      setFilePreview(URL.createObjectURL(file));
    } else {
      setImageError("Invalid file type. Please upload a JPEG or JPG image.");
      //console.error("Invalid file type. Only JPEG or JPG images are allowed.");
      //alert("Invalid file type. Please upload a JPEG or JPG image.");
    }
  } else {
    console.error("No file selected");
  }
};
  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setInputValues({ ...inputValues, snap: file });
  //     setFilePreview(URL.createObjectURL(file));
  //   } else {
  //     console.error("No file selected");
  //   }
  // };

  const handleDeleteImage = (e) => {
    e.stopPropagation(); // Prevent triggering the parent click event
    setInputValues({ ...inputValues, image: null });
    setFilePreview(null);
    inputRef.current.value = "";
  };

  const handleUploadClick = () => {
    inputRef.current.click();
  };

  const resetForm = () => {
    setInputValues({
      category_id: "",
      supplier_company_id: "",
      kia_specification: "",
      product_description: "",
      quantity: "",
      snap: null,
      supplier_price: "",
      unit_of_measurement: "",
      vendor_price: "",

    })
    setFilePreview(null);
    inputRef.current.value = "";
  };

  const submitInventoryDetails = (e) => {
    e.preventDefault();
     addInventorySchema.validate(inputValues, { abortEarly: false })
            .then(() => {
                if(editRowData?.id){
                  editRequestApi()
                }else{
                  addRequestAPI();
                }
               
                setErrors({});
            })
            .catch((validationErrors) => {
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });
                setErrors(newErrors);
            });
  };

  const addRequestAPI = async () => {
    setLoading(true);
    try {
        const dataToBeSent = new FormData();
        dataToBeSent.append('category_id', inputValues?.category_id);
        dataToBeSent.append('snap', inputValues?.snap);
        dataToBeSent.append('supplier_company_id', inputValues?.supplier_company_id);
        dataToBeSent.append('kia_specification', inputValues?.kia_specification);
        dataToBeSent.append('product_description', inputValues?.product_description);
        dataToBeSent.append('quantity', inputValues?.quantity);
        dataToBeSent.append('supplier_price', inputValues?.supplier_price);
        dataToBeSent.append('unit_of_measurement', inputValues?.unit_of_measurement);
        dataToBeSent.append('vendor_price', inputValues?.vendor_price);
        const response = await dispatch(addInventoryApi(dataToBeSent));
        showToast('Inventory added successfully', 1)
        dispatch(getInventoryApi())
        setLoading(false)
        handleCancel();
    } catch (error) {
        showToast(error, 2);
    }
    
  }

  const editRequestApi = async () => {
    setLoading(true);
    try {
      const dataToBeSent = new FormData();
      dataToBeSent.append('product_id', editRowData?.id);
      dataToBeSent.append('category_id', inputValues?.category_id);
      dataToBeSent.append('supplier_company_id', inputValues?.supplier_company_id ? inputValues?.supplier_company_id : editRowData?.supplier_id);
      dataToBeSent.append('kia_specification', inputValues?.kia_specification);
      dataToBeSent.append('product_description', inputValues?.product_description);
      dataToBeSent.append('quantity', inputValues?.quantity);
      dataToBeSent.append('supplier_price', inputValues?.supplier_price);
      dataToBeSent.append('unit_of_measurement', inputValues?.unit_of_measurement);
      dataToBeSent.append('vendor_price', inputValues?.vendor_price);
      if (filePreview) {
        dataToBeSent.append('snap', inputValues?.snap);
      } else {
        dataToBeSent.append('snap', inputValues?.snap || editRowData?.snap);
      }
      const response = await dispatch(editInventoryApi(dataToBeSent));
      if(response?.payload?.status === 'success'){
        showToast(response?.payload?.message, 1)
        dispatch(getInventoryApi())
        setLoading(false)
        handleCancel();
      }else{
        showToast('Failed to edit product', 2)
      }
    } catch (error) {
      showToast(error, 2);
    }
    
}

  const handleCancel = () => {
    resetForm();
    setErrors({});
    handleClose();
  };

  useEffect(() => {
    setInputValues(editRowData? editRowData : null)
    // if (editRowData && editRowData.snap) {
    //   setFilePreview(editRowData.snap); // Assuming editRowData.image contains the URL of the image
    // }
  },[editRowData])
  return (
    <Modal
      open={open}
      onClose={() => {}}
      className={ModalCss?.customModal}
      BackdropProps={{
        style: { pointerEvents: "none" }, // Disable click events on the backdrop
      }}
      
    >
      <Card className={`modal ${ModalCss?.customModal_md}`}>
        <CardHeader
          className="popup-header-rev"
          title={
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
              <Typography variant="h6">Add item to Inventory</Typography>
            </Box>
          }
        />
        <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
        <IconButton onClick={handleCancel}>
              <HighlightOffIcon />
            </IconButton>
        </Stack>
        <Divider />
        <CardContent sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth margin="normal">
        <FormLabel htmlFor="supplier-company-select">Supplier Organization</FormLabel>
        <Select
          id="supplier-company-select"
          value={inputValues?.supplier_company_id ? inputValues?.supplier_company_id : Number(inputValues?.supplier_id)}
          onChange={handleInputChange}
          name="supplier_company_id"
          sx={{ marginTop: "10px" }}
        >
          {(Array.isArray(supplierList) ? supplierList : []).map((supplier) => (
            <MenuItem key={supplier.id} value={supplier.id}>
              {supplier.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText
          error={Boolean(errors.supplier_company_id)}
          sx={{ cursor: 'pointer' }} // Added cursor style
        >
          {errors?.supplier_company_id}
        </FormHelperText>
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth margin="normal">
        <FormLabel htmlFor="category-select">Material Category</FormLabel>
        <Select
          id="category-select"
          value={inputValues?.category_id ? inputValues?.category_id : Number(inputValues?.category_id)}
          onChange={handleInputChange}
          name="category_id"
          sx={{ marginTop: "10px" }}
        >
        {(Array.isArray(categoryList) ? categoryList : []).map((cate) => (
            <MenuItem key={cate.id} value={cate.id}>
              {cate.category}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={Boolean(errors.category_id)}  sx={{ cursor: 'pointer' }} >
          {errors.category_id}
        </FormHelperText>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth margin="normal">
        <FormLabel htmlFor="kia-specification">Supplier Specification</FormLabel>
        <TextField
          id="kia-specification"
          value={inputValues?.kia_specification}
          onChange={handleInputChange}
          name="kia_specification"
          error={Boolean(errors.kia_specification)}
          helperText={errors.kia_specification}
          sx={{ marginTop: "10px" }}
          inputProps={{ maxLength: 100 }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <FormControl fullWidth margin="normal">
        <FormLabel htmlFor="unit-of-measurement">Unit of Measurement</FormLabel>
        <TextField
          id="unit-of-measurement"
          name="unit_of_measurement"
          value={inputValues?.unit_of_measurement}
          error={Boolean(errors.unit_of_measurement)}
          helperText={errors.unit_of_measurement}
          onChange={handleInputChange}
          sx={{ marginTop: "10px" }}
          inputProps={{ maxLength: 10 }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <FormControl fullWidth margin="normal">
        <FormLabel htmlFor="quantity">Quantity</FormLabel>
        <TextField
          id="quantity"
          name="quantity"
          value={inputValues?.quantity}
          error={Boolean(errors.quantity)}
          helperText={errors.quantity}
          onChange={handleInputChangeQuantity}
          sx={{ marginTop: "10px" }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <FormControl fullWidth margin="normal">
        <FormLabel htmlFor="supplier-price">Supplier Price</FormLabel>
        <TextField
          id="supplier-price"
          name="supplier_price"
          value={inputValues?.supplier_price}
          error={Boolean(errors.supplier_price)}
          helperText={errors.supplier_price}
          onChange={handleInputChangePrice}
          sx={{ marginTop: "10px" }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <FormControl fullWidth margin="normal">
        <FormLabel htmlFor="vendor-price">Dealer Price</FormLabel>
        <TextField
          id="vendor-price"
          name="vendor_price"
          value={inputValues?.vendor_price}
          error={Boolean(errors.vendor_price)}
          helperText={errors.vendor_price}
          onChange={handleInputChangePrice}
          sx={{ marginTop: "10px" }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth margin="normal">
        <FormLabel htmlFor="product-description">Product Description</FormLabel>
        <TextField
          id="product-description"
          name="product_description"
          value={inputValues?.product_description}
          error={Boolean(errors.product_description)}
          helperText={errors.product_description}
          onChange={handleInputChange}
          sx={{ marginTop: "10px" }}
          inputProps={{ maxLength: 100 }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12}>

      <Box className={classes.imageUpload} onClick={filePreview ? null : handleUploadClick}>
          <input
            type="file"
            ref={inputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="image/jpg, image/jpeg"
          />
          
          {filePreview ? (
            <>
              <img src={filePreview} alt="Preview" className={classes.previewImage} />
              <CloseIcon className={classes.deleteIcon} onClick={handleDeleteImage} />
            </>
          ) : editRowData?.snap ? (
            <>
              <img src={`${editRowData.snap}`} alt="Snap" className={classes.previewImage} />
              <FileUploadIcon />
              <Typography className={classes.imageUploadText}>
                Drag your image here, or browse
              </Typography>
            </>
          ) : (
            <>
              <FileUploadIcon />
              <Typography className={classes.imageUploadText}>
                Drag your image here, or browse
              </Typography>
            </>
          )}
          {imageError && <FormHelperText error>{imageError}</FormHelperText>}
    </Box>

      {/* <Box className={classes.imageUpload} onClick={filePreview ? null : handleUploadClick}>
        <input
          type="file"
          ref={inputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        {filePreview ? (
          <>
            <img src={filePreview} alt="Preview" className={classes.previewImage} />
            <CloseIcon className={classes.deleteIcon} onClick={handleDeleteImage} />
          </>
        ) : (
          <>
            <FileUploadIcon />
            <Typography className={classes.imageUploadText}>
              Drag your image here, or browse
            </Typography>
          </>
        )}
      </Box> */}
    </Grid>
  </Grid>
</CardContent>


        <CardActions disableSpacing>
          <Box component={"div"} width={"100%"} marginBlockStart={0.5} className={classes.buttonContainer}>
            <Button
              variant="contained"
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: 1.8,
                boxShadow: "none",
                backgroundColor: "#000 !important",
                p: {
                  lg: "15px 46px",
                  md: "13px 35px",
                  sm: "10px 30px",
                  xs: "10px 30px",
                },
                color: "#fff !important",
              }}
              className='white-btn'
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: 1.8,
                boxShadow: "none",
                backgroundColor: "#000 !important",
                p: {
                  lg: "15px 46px",
                  md: "13px 35px",
                  sm: "10px 30px",
                  xs: "10px 30px",
                },
                color: "#fff !important",
              }}
              className={classes.buttonsStyle}
              onClick={submitInventoryDetails}
              disabled={loading}
            >
            {loading ? "Loading..." : editRowData?.id ? "Update Inventory" : "Add Inventory"} 
               
            </Button>
          </Box>
        </CardActions>
        </Card>
      </Modal>
    );
  };

  export default AddInventory;
