import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    IconButton,
    Modal,
    Stack,
    TextareaAutosize,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { showToast } from "../../toast/toast";
import { sendFeedback } from "../../store/home/sendFeedback";

const useStyles = makeStyles((theme) => ({
    bgLightGray: {
        backgroundColor: "rgba(213, 222, 229, 1)",
    },
    borderRadius10: {
        borderRadius: "10px",
    },
    p10: {
        padding: "10px",
    },
    p20: {
        padding: "20px",
    },
    buttonsStyle: {
        backgroundColor: "#D7EDFE",
    },
    fullWidth: {
        width: "100%",
    },
}));

const RequestForRevision = ({ open, handleClose, id, getData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState("");
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);

    const handleChange = (event) => {
        setFeedback(event.target.value);
    };
    const sendFeedbackSubmit = async () => {
        if (feedback === "") {
            showToast("Please Enter Revision Request",2);
            return;
        }
        const formData = new FormData();
        formData.append('form_id', formId?.data?.id);
        formData.append('user_type', "KIA");
        formData.append('feedback', feedback);
        formData.append('feedback_on', "Gfc layout");
        formData.append('added_for', 'INN');


        await dispatch(sendFeedback(formData))
            .then((data) => {
                getData();
                showToast(data?.payload?.message, 1);
                setFeedback("");
                handleClose()
            })

            .catch((error) => {
                console.error("Error sending revision:", error);
            });
    };
    const handleCloseModal = () => {
        setFeedback("");
        handleClose();
    }

    return (
        <>
            <Modal
                open={open}
                onClose={()=>{ setFeedback(""); handleClose()}}
                className={ModalCss?.customModal}
            >
                <Card className={`modal ${ModalCss?.customModal_md}`}>
                    <CardHeader
                        sx={{ textAlign: "center" }}
                       
                        title="Raise a Revision"
                    />
<Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
<IconButton onClick={()=>{ setFeedback(""); handleClose()}}>
                                <HighlightOffIcon />
                            </IconButton>
        </Stack>
                    <Box style={{ maxWidth: "500px",marginInline: "auto"}}>
                    <CardContent className="d-flex" sx={{paddingInline: "0px"}}>
                        <TextareaAutosize style={{ width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }} className="bg-white mx-auto" minRows={6}
                            placeholder={"Enter your revision request"}

                           value={feedback}
                            onChange={handleChange} />
                    </CardContent>
                    <CardActions disableSpacing sx={{paddingInline: "0px"}}>
                        <Box component={"div"}
                            width={"100%"}
                            marginBlockStart={0.5}
                            display={"flex"}
                            justifyContent={"end"}
                            alignItems={"center"}
                            gap={2}
                            >
                                

                            <Button
                                variant="text"
                                size="small"
                                sx={{
                                    color: "rgba(167, 177, 187, 1)",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    textDecoration: "underline",
                                }}
                                onClick={()=>{setFeedback(""); handleClose()}}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => sendFeedbackSubmit()}
                                sx={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    lineHeight: 1.8,
                                    boxShadow: "none",
                                    backgroundColor: "#000 !important",
                                }}
                                className={classes.buttonsStyle}
                            >
                                Send
                            </Button>
                        </Box>
                    </CardActions>
                    </Box>
                    
                    
                </Card>
            </Modal>
        </>
    );
};

export default RequestForRevision;
