import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Style from "../../styles/home.module.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import layoutIconSvg from "../../assets/img/layout-svg.png";
import { useDispatch, useSelector } from "react-redux";
import { inputDrawingApi } from "../../store/home/layoutTable";
import { resetInputDrawingByClusterState } from "../../store/home/getInputDrawing";
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import { layoutCountApi } from "../../store/home/countReducers";

export default function DealortConstructions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputDrawing = useSelector((state) => state?.inputDrawingReducer?.result?.data);
  const countData = useSelector((state) => state?.countReducer?.layoutCountApi?.result);
  const handleNavigate = (url) => {
    navigate(`/${url}`);
  }

  useEffect(() => {
    dispatch(inputDrawingApi());
    dispatch(resetInputDrawingByClusterState())
    dispatch(layoutCountApi())

  }, [dispatch]);

  return (
    <Box>
      <Box className={Style?.headerSection}>
        <Typography className={Style?.cardHeading} variant="h6">
          Layout Management
        </Typography>
      </Box>

      <Stack sx={{ mt: 2 }}>
        <Grid container spacing={1}>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard2} onClick={() =>handleNavigate('layout')} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
              <ViewComfyOutlinedIcon/>

                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                  Layout
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                  {inputDrawing?.length ? inputDrawing?.length : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard2} onClick={() =>handleNavigate('layout')} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
              <ViewComfyOutlinedIcon/>

                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                  Input Drawing
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                  {countData?.input_drawings}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid> */}

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard3} onClick={() =>handleNavigate('layout')} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
              <ViewComfyOutlinedIcon/>

                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                    Schematics
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                  {countData?.schematic ? countData?.schematic : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard4} onClick={() =>handleNavigate('layout')} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
              <ViewComfyOutlinedIcon/>

                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                    GFC
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                  {countData?.gfc ? countData?.gfc : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard5} onClick={() =>handleNavigate('layout')} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
              <ViewComfyOutlinedIcon/>

                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                    Design Estimates
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                  {countData?.design_estimate ? countData?.design_estimate : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          
        </Grid>
      </Stack>
    </Box>
  );
}
