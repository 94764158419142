import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, Box } from '@mui/material';

const DeleteConfirmationModal = ({ open, handleClose, handleConfirm, deleteType }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this {deleteType}? This action cannot be undone.
                </DialogContentText>
               
                {/* Center the buttons */}
                <Box mt={2} display="flex" justifyContent="center" gap={2}>
                    <Button
                      variant="contained"
                      className={'btn white-btn'}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleConfirm}
                      className={'btn black-btn'}
                    >
                      Delete
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
export default DeleteConfirmationModal;
