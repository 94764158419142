import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, Box, Tooltip, Typography, Modal, Card, CardHeader, Divider, Stack, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { handleDownload } from './downloadFile';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ModalCss from "../../styles/modal.module.scss";
import { formatDateString } from '../../utils/dateFormater';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import pdf from '../../assets/img/pdf.png'


const ViewArrayItemModal = ({ open, handleClose, dataArray, dataType }) => {
    console.log(dataArray, "dataArray");
    return (
        <Modal open={open} onClose={handleClose} className={ModalCss.customModal}>
            <Card className={`modal ${ModalCss.customModal_md}`}>
                <CardHeader sx={{ textAlign: "center" }} title="Uploaded Invoice" />
                {/* <Typography component="div" sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}>
                    You can view your uploaded invoice here
                </Typography> */}
                <Divider sx={{ width: '100%', mt: 1, mb: 2 }} />
                <Box
                    component="div"
                    sx={{
                        maxWidth: "800px",  // Adjusted max-width for better fit
                        margin: "0 auto",
                        textAlign: "center",
                        padding: "20px",
                    }}
                >
                    <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
                        <IconButton onClick={handleClose}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        justifyContent="center"
                        direction="column"
                        gap={2}
                        alignItems="center"
                        fontSize={"18px"}
                        boxSizing={"border-box"}
                        component={"div"}
                    >
                        <TableContainer component={Paper} sx={{ maxWidth: "100%", marginTop: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>File</TableCell>
                                        <TableCell align='center'>Invoice Amount</TableCell>
                                        <TableCell align='center'>Created Date</TableCell>
                                        <TableCell align='center'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataArray.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align='center'>
                                                <img width={40} height={40} src={pdf} style={{ marginTop: "10px", marginBottom: "10px" }} alt="PDF File" />
                                            </TableCell>
                                            <TableCell align='center'>{item.invoice_amount}</TableCell>
                                            <TableCell align='center'>{formatDateString(item.invoice_uploaded_on)}</TableCell>
                                            <TableCell align='center'>
                                                <IconButton 
                                                
                                                 //onClick={() => handleDownload(item.upload_invoice)}
                                                 onClick={() => handleDownload(new Date() + 'invoice', item.files)}
                                                 >
                                                    <DownloadForOfflineRoundedIcon />
                                                </IconButton>
                                                <IconButton onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${item.files}`)}>
                                                    <FullscreenIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </Box>
            </Card>
        </Modal>
        // <Dialog open={open} onClose={handleClose}>
        //     <DialogTitle>View {dataType} <IconButton
        //     aria-label="close"
        //     onClick={handleClose}
        //     sx={{
        //         position: 'absolute',
        //         right: 8,
        //         top: 8,
        //         color: (theme) => theme.palette.grey[500],
        //     }}
        // >
        //     <CloseIcon />
        // </IconButton></DialogTitle>
        //     <DialogContent>
        //         <DialogContentText>
        //             You can view and download {dataType}
        //         </DialogContentText>
               
        //         {/* Center the buttons */}
        //         <Box mt={2} display="flex" justifyContent="center">
        //             <Box sx={{ width: "213px" }}>
        //             {dataArray && dataArray.length > 0 ? (
        //                 dataArray.map((data, index) => (
        //                     <Box key={index} className="text-center">
        //                         <Box className="p-relative">
        //                             <Tooltip title={data.files} placement="top" className="uploadedfilename">
        //                                 <Typography>
        //                                     {dataType}
        //                                 </Typography>
        //                                 <DownloadForOfflineRoundedIcon 
        //                                     className="my-auto" 
        //                                     onClick={() => handleDownload(new Date() + dataType, data.files)} 
        //                                 />
        //                                 <FullscreenIcon 
        //                                     className="my-auto" 
        //                                     onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${data.files}`)} 
        //                                 />
        //                             </Tooltip>
        //                         </Box>
        //                     </Box>
        //                 ))
        //             ) : (
        //                 <Typography variant="body1" align="center">
        //                     No data found
        //                 </Typography>
        //             )}

        //             </Box>
        //         </Box>
                
        //     </DialogContent>
        // </Dialog>
    );
};
export default ViewArrayItemModal;
