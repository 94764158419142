import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Modal,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { showToast } from "../../toast/toast";
import { orderStatus } from "../../utils/orderStatus";
import { updateOrderStatus } from "../../store/orders/updateOrderStatus";
import { ordersApi } from "../../store/orders/orders";
import { acceptDeclineRefundAPI } from "../../store/orders/refundOrder";

const useStyles = makeStyles(() => ({
  buttonsStyle: {
    backgroundColor: "#D7EDFE",
  },
}));

const CancelOrderModal = ({ open, handleClose, mainOrderId, type, cancelOrderData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState("");
  const [refundAccept, setRefundAccept] = useState(false);
  const handleChange = (event) => {
    setFeedback(event.target.value);
  };

  const sendFeedbackSubmit = async () => {
    if (feedback === "") {
      showToast(`Please enter a comment`, 2);
      return;
    }
    let currentStatus;
    if(type === "cancel"){
      currentStatus = orderStatus.order_cancelled;
    }
    if(type==="refund"){
      currentStatus = orderStatus.refund_initiated;
    }
    const formData = new FormData();
    let apiUrl = "";
    if(type === 'cancel'){
      formData.append("main_order_id", mainOrderId);
      formData.append("comment", feedback);
      apiUrl = updateOrderStatus(formData)
    }

    if(type === 'refund'){
      formData.append("main_order_id", mainOrderId);
      formData.append("comment", feedback);
      formData.append("refund_accept", refundAccept);
      apiUrl = acceptDeclineRefundAPI(formData)
    } 
    await dispatch(apiUrl)
      .then((data) => {
        setTimeout(() => {
          dispatch(ordersApi())
        }, 2000);
        showToast(currentStatus, 1);
        setFeedback("");
        handleClose();
      })
      .catch((error) => {
        console.error("Error sending feedback:", error);
      });
  };
  const handleCloseModal = () => {
    setFeedback("");
    handleClose();
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md} ` }>
          <CardHeader
            sx={{ textAlign: "center", paddingBottom: 0,fontWeight: 500,fontSize: "1.25rem" }}
            
            title={type ==='cancel' ? 'Cancel Order': "Request Refund"}
          />
          <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
          <IconButton onClick={handleCloseModal}>
                <HighlightOffIcon />
              </IconButton>
        </Stack>
          <Divider variant="middle" style={{ marginTop: "10px" }} />
          <Box
            className="mx-auto"
            style={{ maxWidth: "500px", width: "100%", padding: "20px" }}
          >
            <Typography
              variant="body1"
              color="textSecondary"
              style={{
                marginBottom: "15px",
                textAlign: "center",
                fontSize: "17px",
                color:"rgba(0,0,0,0.9)",
                fontWeight:500
              }}
            >
            {type ==='cancel' ? 'Are you sure you want to cancel this order?': "Your are requesting for refund."}
            
            </Typography>
            {/* <Typography
              variant="body1"
              color="textSecondary"
              style={{
                marginBottom: "15px",
                textAlign: "center",
                fontSize: "14px",
              }}
              >Please provide any additional feedback below.</Typography> */}
            <CardContent
              className="d-flex"
              style={{
                width: "100%",
                paddingInline: "0",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <TextareaAutosize
                style={{
                  width: "100%",
                  border: "1px solid #C4D3DF",
                  borderRadius: "5px",
                  padding: "10px",
                }}
                className="bg-white mx-auto"
                minRows={4}
                placeholder={`Enter your comment`}
                value={feedback}
                onChange={handleChange}
              />
            </CardContent>
            <CardActions
              disableSpacing
              style={{ width: "100%", paddingInline: "0", marginTop: "20px" }}
            >
              <Box
                component={"div"}
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                gap={2}
              >
                {/* <Button
                    variant="contained"
                    color='primary'
                    onClick={handleCloseModal}
                    sx={{ mr: 1 }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => sendFeedbackSubmit()}
                    color="error"
                  >
                    Yes
                  </Button> */}
                  <Button
                      variant="contained"
                      className={'btn white-btn'}
                      onClick={handleCloseModal}
                    >
                      No
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => sendFeedbackSubmit()}
                      className={'btn black-btn'}
                    >
                      Yes
                    </Button>
              </Box>
            </CardActions>
          </Box>
        </Card>
      </Modal>
    </>
  );
};

export default CancelOrderModal;
