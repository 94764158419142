import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Modal,
    Stack,
    TextareaAutosize,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import HighlightOffIcon from "@mui/icons-material/HighlightOff";
  import ModalCss from "../../styles/modal.module.scss";
  import { makeStyles } from "@mui/styles";
  import { useDispatch } from "react-redux";
  import { showToast } from "../../toast/toast";
  import { orderStatus } from "../../utils/orderStatus";
  import { updateOrderStatus } from "../../store/orders/updateOrderStatus";
  import { ordersApi } from "../../store/orders/orders";
  import { acceptDeclineRefundAPI } from "../../store/orders/refundOrder";
  
  const useStyles = makeStyles(() => ({
    buttonsStyle: {
      backgroundColor: "#D7EDFE",
    },
  }));
  
  const RefundAceptModal = ({ open, handleClose, mainOrderId, type, refundAcceptData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState("");
    const [refundAccept, setRefundAccept] = useState(false);
    const handleChange = (event) => {
      setFeedback(event.target.value);
    };
  
    const sendFeedbackSubmit = async (refundAccept) => {
        if (feedback === "") {
        showToast(`Please enter a comment`, 2);
        return;
        }
        let refund_accept;
        if(refundAccept===1){
            refund_accept = false;
        }
        if(refundAccept===2){
            refund_accept = true;
        }
        
        const dataTobeSent = {
            order_id: refundAcceptData?.id,
            comment: feedback,
            refund_accept: refundAccept
        }
        // const formData = new FormData();
        // formData.append("order_id", refundAcceptData?.id);
        // formData.append("comment", feedback);
        // formData.append("refund_accept", refund_accept);
        const apiUrl = acceptDeclineRefundAPI(dataTobeSent)
      
      await dispatch(apiUrl)
        .then((data) => {
          setTimeout(() => {
            dispatch(ordersApi())
          }, 2000);
          showToast(data?.payload?.message, 1);
          setFeedback("");
          handleClose();
        })
        .catch((error) => {
          console.error("Error sending feedback:", error);
        });
    };
    const handleCloseModal = () => {
      setFeedback("");
      handleClose();
    }
  
    return (
      <>
        <Modal
          open={open}
          onClose={handleCloseModal}
          className={ModalCss?.customModal}
        >
          <Card className={`modal ${ModalCss?.customModal_md} ` }>
            <CardHeader
              sx={{ textAlign: "center", paddingBottom: 0 }}
             
              title={"Refund Requested"}
            />
            <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
            <IconButton onClick={handleCloseModal}>
                  <HighlightOffIcon />
                </IconButton>
        </Stack>

            <Divider variant="middle" style={{ marginTop: "10px" }} />
            <Box
              className="mx-auto"
              style={{ maxWidth: "500px", width: "100%", padding: "20px" }}
            >
              <Typography
                variant="body1"
                // color="textSecondary"
                style={{
                  marginBottom: "15px",
                  textAlign: "center",
                  fontSize:"17px"
                }}
              >
              Dealer has requested for Refund for this order
              </Typography> 
              {/* <Typography
                variant="body1"
                color="textSecondary"
                style={{
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
              <strong>Reason for Cancelling Order :</strong> {refundAcceptData?.refund_comment}
              
              </Typography> */}
              {/* <Typography
                variant="body1"
                color="textSecondary"
                style={{
                  marginBottom: "15px",
                  textAlign: "center",
                  fontSize: "14px",
                  //color: "#C4D3DF",
                }}
                >Please add comment</Typography> */}
              <CardContent
                className="d-flex"
                style={{
                  width: "100%",
                  paddingInline: "0",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <TextareaAutosize
                  style={{
                    width: "100%",
                    border: "1px solid #C4D3DF",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                  className="bg-white mx-auto"
                  minRows={4}
                  placeholder={`Enter your comment`}
                  value={feedback}
                  onChange={handleChange}
                />
              </CardContent>
              <CardActions
                disableSpacing
                style={{ width: "100%", paddingInline: "0", marginTop: "20px" }}
              >
                <Box
                  component={"div"}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={2}
                >
                  <Button
                      variant="contained"
                      className={'btn white-btn'}
                      onClick={() => sendFeedbackSubmit(false)}
                    >
                      Reject
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => sendFeedbackSubmit(true)}
                      className={'btn black-btn'}
                    >
                      Approve
                    </Button>
                </Box>
              </CardActions>
            </Box>
          </Card>
        </Modal>
      </>
    );
  };
  
  export default RefundAceptModal;
  