import React from "react";
import { Box, Button, Grid, OutlinedInput, Tooltip, Typography, TextareaAutosize, Card } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInputDrawingByClusterIdApi } from "../../store/home/getInputDrawing";
import { convertToFormData } from "../ConvertToFormData";
import { sendToKin } from "../../store/home/postToKin";
import Feedback from "../reusable/Feedback";
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { handleDownload } from "../reusable/downloadFile";
import { showToast } from "../../toast/toast";
import DoneIcon from '@mui/icons-material/Done';
export default function InputDrawing({ dealorOutletId }) {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [approved, setApproved] = useState(false);
    const handleOpen = (id) => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isSentFeedBack, setIsSentFeedBack] = useState(false);
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    localStorage.removeItem("isRevisedViewClick")
    const getInputDrawing = async () => {
        const formD = {
            'dealor_outlet_id': dealorOutletId
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(getInputDrawingByClusterIdApi(data));
            setData(response?.payload?.data);
        } catch (error) {
            
        }
    }

    
    const postToKin = async () => {
        const formData = convertToFormData({ 'form_id': formId?.data?.id });
        try {
            const response = await dispatch(sendToKin(formData));
            showToast(response?.payload?.message, 1);
            if (response?.payload?.status) {
                setApproved(true);
                setIsSentFeedBack(true);
            }
        } catch (error) {
            console.error("Error in sending data to KIA:", error);
        }
    };

    useEffect(() => {
        getInputDrawing();
    }, []);

    useEffect(() => {
        if(formId){
            setApproved(formId?.data?.approve_by_admin===true ? true : false);
        }
        
    },[formId])

    
    useEffect(() => {
      if(data?.revision_request===true || data?.approve_by_admin===true){
        setIsSentFeedBack(true);
      }
    },[data])
    return (
        <>
            <Box className="h-100 d-flex flex-column justify-content-between">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <Typography variant="h6" className="fs-17  fw-400">Input Drawing</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                            <Box sx={{ width: "213px" }}>
                                {data?.input_pdf?.map((schematicLayoutData) => (
                                    <>
                                        <Box className="text-center">
                                            <Box className="p-relative">
                                                <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`} >
                                                    <img width={40} height={40} src={pdf} className="my-auto" />
                                                    <Typography>
                                                        {'PDF File'}
                                                    </Typography>
                                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'inputdrawing', schematicLayoutData.files)} />
                                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </>
                                ))}
                            </Box>
                            <Box sx={{ width: "213px" }}>
                                {data?.input_cad?.map((schematicLayoutData) => (
                                    <>
                                        <Box className="text-center">
                                            <Box className="p-relative">
                                                <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`} >
                                                    <img width={40} height={40} src={cad} className="my-auto" />
                                                    <Typography>
                                                        {'CAD File'}
                                                    </Typography>
                                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'inputdrawing_cad', schematicLayoutData.files)} />
                                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </>
                                ))}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Grid container spacing={2} sx={{ marginTop: "50px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h6" className="fs-17 ">Sections Heights (in Ft.):</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Road to Start of Building</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.distance_from_road} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Building Total Height</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.building_height} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Ceiling Height</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.ceiling_height} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Beam Height</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.beam_height} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ marginTop: "50px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h6" className="fs-17 ">Set Back Area (in Ft.):</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Front Side</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.front_side ? data?.front_side : ""} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Left Side</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.left_side ? data?.left_side : ""} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Right Side</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.right_side ? data?.right_side : ""} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Back Side</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.back_side ? data?.back_side : ""} />
                            </Grid>
                            
                            
                        </Grid>

                        <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Attach Images (Long Shot & Clear Image Required):</Typography>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                                <Typography variant="h6" className="fs-13 w-100" sx={{ marginTop: "22px" }}>Interior Images</Typography>
                                {data?.layout_interior?.map((interiorPhotos) => {
                                    return (
                                        <>
                                        <Grid item xs={12} sm={6} md={6} lg={4}>
                                          <Box
                                            sx={{
                                              border: "1px solid #e7eaed",
                                              borderRadius: "10px",
                                              background: "#f2f2f2",
                                              overflow: "hidden",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                maxHeight: "180px",
                                                minHeight: "180px",
                                                objectFit: "cover",
                                              }}
                                              src={`${process.env.REACT_APP_BASE_URL}${interiorPhotos?.photos}`}
                                              alt=""
                                            />
                                            <Box
                                              className="text-center justify-center d-flex"
                                              sx={{ gap: "7px", marginBlock: "10px" }}
                                            >
                                              <Button
                                                className="link-btn d-flex gap-5"
                                                sx={{ fontSize: "13px !important" }}
                                                onClick={() => {
                                                      const imageUrl = `${process.env.REACT_APP_BASE_URL}${interiorPhotos?.photos}`;
                                                      const newWindow = window.open();
                                                      newWindow.document.write(`<img src="${imageUrl}" alt="Full View" style="width:100%;height:auto;">`);
                                                    newWindow.document.close();
                                                }}
                                              >
                                                <svg
                                                  width="11"
                                                  height="12"
                                                  viewBox="0 0 11 12"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M10.639 0.943359H7.414V1.66811H9.76888L6.67578 4.77787L7.18527 5.29042L10.2787 2.18051V4.54856H10.9993V1.30573L10.639 0.943359Z"
                                                    fill="#05141F"
                                                  />
                                                  <path
                                                    d="M3.81345 7.6543L0.720633 10.7645V8.3963H0V11.6397L0.360316 12.0021H3.58472V11.2773H1.22998L4.32308 8.16684L3.81345 7.6543Z"
                                                    fill="#05141F"
                                                  />
                                                  <path
                                                    d="M0.72121 4.54856L0.720777 2.17616L3.81316 5.291L4.32308 4.77874L1.23473 1.66811H3.58529V0.943359H0.360316L0 1.30588L0.000576602 4.54871L0.72121 4.54856Z"
                                                    fill="#05141F"
                                                  />
                                                  <path
                                                    d="M10.2779 10.7586L7.18498 7.65234L6.67578 8.16518L9.77277 11.2755L7.41328 11.276L7.41342 12.0007L10.6384 12.0001L10.9986 11.6377V8.39492H10.2779V10.7586Z"
                                                    fill="#05141F"
                                                  />
                                                </svg>
                                                Full View
                                              </Button>
                                            </Box>
                                          </Box>
                                        </Grid>
                                      </>
                                    )
                                }
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                                <Typography variant="h6" className="fs-13 w-100" sx={{ marginTop: "22px" }}>Exterior Images</Typography>
                                {data?.layout_exterior?.map((exteriorPhotos, index) => {
                                    return (
                                        <>
                                        <Grid item xs={12} sm={6} md={6} lg={4}>
                                          <Box
                                            sx={{
                                              border: "1px solid #e7eaed",
                                              borderRadius: "10px",
                                              background: "#f2f2f2",
                                              overflow: "hidden",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                maxHeight: "180px",
                                                minHeight: "180px",
                                                objectFit: "cover",
                                              }}
                                              src={`${process.env.REACT_APP_BASE_URL}${exteriorPhotos?.photos}`}
                                              alt=""
                                            />
                                            <Box
                                              className="text-center justify-center d-flex"
                                              sx={{ gap: "7px", marginBlock: "10px" }}
                                            >
                                              <Button
                                                className="link-btn d-flex gap-5"
                                                sx={{ fontSize: "13px !important" }}
                                                onClick={() => {
                                                    const imageUrl = `${process.env.REACT_APP_BASE_URL}${exteriorPhotos?.photos}`;
                                                    const newWindow = window.open();
                                                    newWindow.document.write(`<img src="${imageUrl}" alt="Full View" style="width:100%;height:auto;">`);
                                                    newWindow.document.close();
                                                                  }}
                                              >
                                                <svg
                                                  width="11"
                                                  height="12"
                                                  viewBox="0 0 11 12"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M10.639 0.943359H7.414V1.66811H9.76888L6.67578 4.77787L7.18527 5.29042L10.2787 2.18051V4.54856H10.9993V1.30573L10.639 0.943359Z"
                                                    fill="#05141F"
                                                  />
                                                  <path
                                                    d="M3.81345 7.6543L0.720633 10.7645V8.3963H0V11.6397L0.360316 12.0021H3.58472V11.2773H1.22998L4.32308 8.16684L3.81345 7.6543Z"
                                                    fill="#05141F"
                                                  />
                                                  <path
                                                    d="M0.72121 4.54856L0.720777 2.17616L3.81316 5.291L4.32308 4.77874L1.23473 1.66811H3.58529V0.943359H0.360316L0 1.30588L0.000576602 4.54871L0.72121 4.54856Z"
                                                    fill="#05141F"
                                                  />
                                                  <path
                                                    d="M10.2779 10.7586L7.18498 7.65234L6.67578 8.16518L9.77277 11.2755L7.41328 11.276L7.41342 12.0007L10.6384 12.0001L10.9986 11.6377V8.39492H10.2779V10.7586Z"
                                                    fill="#05141F"
                                                  />
                                                </svg>
                                                Full View
                                              </Button>
                                            </Box>
                                          </Box>
                                        </Grid>
                                      </>
                                      )
                                }
                                )}
                            </Box>
                        </Grid>
                        <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Comment (if any)</Typography>
                        <TextareaAutosize disabled style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }} className="bg-white" minRows={6} placeholder={data?.comment} />

                        <Typography
                variant="h6"
                className="fs-17 "
                sx={{ marginTop: "50px", paddingBottom: "10px" }}
              >
                Attachments
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  maxHeight: "200px",
                  overflowY: "auto",
                  marginBottom: "20px",
                }}
              >
                <Grid container spacing={{ xs: 1, lg: 2 }}>
                    {Array.isArray(data?.layout_attachment) && data.layout_attachment.length > 0 ? (
                        data.layout_attachment.map((schematicLayoutData) => (
                        <Grid item xs={6} sm={"auto"} key={schematicLayoutData.attachments}>
                            <Box className="bg-white text-center" sx={{ height: "auto !important" }}>
                            <Box className="m-auto p-relative">
                                <Tooltip
                                title={schematicLayoutData.attachments}
                                placement="top"
                                className="uploadedfilename justify-content-between gap-2"
                                width="100% !important"
                                maxWidth="190px !important"
                                >
                                <Typography sx={{ width: "auto !important" }}>Attachment</Typography>
                                <Box className="d-flex flex-nowrap gap-5 ml-auto">
                                    <DownloadForOfflineRoundedIcon
                                    className="my-auto"
                                    onClick={() =>
                                        handleDownload(new Date() + "schematic_cad", schematicLayoutData.attachments)
                                    }
                                    />
                                    <FullscreenIcon
                                    className="my-auto"
                                    onClick={() =>
                                        window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData?.attachments}`)
                                    }
                                    />
                                </Box>
                                </Tooltip>
                            </Box>
                            </Box>
                        </Grid>
                        ))
                    ) : (
                        <Grid item xs={12}>
                        <Typography>No attachment available</Typography>
                        </Grid>
                    )}
                    </Grid>

              </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" sx={{ gap: "23px" }}>
                        {/* {data?.approve_by_innoceans === true ? 
                            <Button className="black-btn" style={{color: "green"}}><DoneIcon style={{ marginRight: "2px", marginTop: "-5px" }}/> Approved</Button>
                        :   
                           
                        } */}
                        {/* <Button className="black-btn" onClick={() => postToKin()}>Send TO kin</Button> */}

                         <Button
                            className="black-btn" 
                        
                            onClick={postToKin}
                            disabled={approved || isSentFeedBack}
                        >
                            {approved ? "Approved" : "Send To Innocean"}
                            {approved && <DoneIcon sx={{ marginLeft: "5px" }} />}
                        </Button>

                        <Button disabled={isSentFeedBack} className="white-btn" onClick={() => handleOpen()}>Send Feedback To Dealer</Button>
                    </Grid>
                </Grid>
            </Box>
            <Feedback open={open} handleClose={handleClose}  feedBackOn='Input Drawing' type="Feedback" getData={getInputDrawing}/>
        </>
    );
}