import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Fade, Grid, Popper, Tooltip, Typography } from "@mui/material";
import { getRevisedSchematic } from "../../store/home/revisedSchematic";
import { useDispatch, useSelector } from "react-redux";
import { convertToFormData } from "../ConvertToFormData";
import { postRevisedLayoutView } from "../../store/home/postRevisedLayoutView";
import { showToast } from "../../toast/toast";
import { handleDownload, handleDownloadMultipleFile } from "../reusable/downloadFile";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function RevisedLayout({ setSelectedTab }) {
  // const formId = useSelector((state) => state?.inputDrawingReducer?.result?.results?.data[0]);
  const formId = useSelector(
    (state) => state?.inputDrawingByClusterReducer?.result
  );
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const designEstimate = async () => {
    const formD = {
      form_id: formId?.data?.id,
    };
    const data = convertToFormData(formD);
    try {
      const response = await dispatch(getRevisedSchematic(data));
      setData(response?.payload?.data);
    } catch (error) {}
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState();
  const [open, setOpen] = useState({});
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;
  const handleClickPopper = (newPlacement, index) => (event) => {
    if (event) {
        setAnchorEl(event.currentTarget);
    }
    setOpen((prev) => ({
        ...prev,
        [index]: !prev[index]
    }));
    setPlacement(newPlacement);
};

const [anchorElCAD, setAnchorElCAD] = useState(null);
const [placementCAD, setPlacementCAD] = useState();
const [openCAD, setOpenCAD] = useState({});
const canBeOpenCAD = openCAD && Boolean(anchorElCAD);
  const idCAD = canBeOpenCAD ? 'transition-popper' : undefined;
const handleClickPopperCad = (newPlacement, index) => (event) => {
  if (event) {
      setAnchorElCAD(event.currentTarget);
  }
  setOpenCAD((prev) => ({
      ...prev,
      [index]: !prev[index]
  }));
  setPlacementCAD(newPlacement);
};


  useEffect(() => {
    designEstimate();
  }, []);

  const handleView = async (data) => {
    try {
      localStorage.setItem("isRevisedViewClick", 1);
      await dispatch(postRevisedLayoutView(data));
      setSelectedTab(3);
    } catch (error) {
      showToast(error, 2);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" className="fs-17  fw-400">
            Schematic Revisions
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className="d-flex flex-wrap" sx={{ gap: "9px" }}>
            <Button className="layout-blue-btn">Schematic by KIA</Button>
            <Button className="layout-lightblue-btn">
              Request from Dealer
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "10px 10px 0 0" }}
          >
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    className="w-50"
                    sx={{ background: "#85949F !important" }}
                    align="center"
                  >
                    Schematic Received Date
                  </StyledTableCell>
                  <StyledTableCell
                    className="w-50"
                    sx={{ background: "#85949F !important" }}
                    align="center"
                  >
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length > 0 ? (
                  <>
                    {" "}
                    {data?.map((row, index) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell
                          align="center"
                          className="w-50"
                          sx={{ borderRight: "1px solid #D9E2EA !important" }}
                        >
                          {row.created_at.split("T")[0]}
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{paddingBlock: "3px !important"}}>
                          <Box
                            className="d-flex justify-center"
                            sx={{ gap: "7px" }}
                          >
                            <Button
                              onClick={() => handleView(row)}
                              className="DownloadBtn"
                              sx={{ gap: "3px" }}
                            >
                              <svg
                                width="13"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.9274 3.74815C12.8858 3.73333 12.194 2.81481 11.0593 1.8963C9.53726 0.651852 7.95982 0 6.49308 0C5.02634 0 3.44891 0.651852 1.92682 1.8963C0.792177 2.81481 0.100319 3.73333 0.072645 3.76296C-0.024215 3.8963 -0.024215 4.08889 0.072645 4.23704C0.100319 4.26667 0.792177 5.18518 1.92682 6.1037C3.44891 7.34815 5.02634 8 6.49308 8C7.95982 8 9.53726 7.34815 11.0593 6.1037C12.194 5.18518 12.8858 4.26667 12.9274 4.22222C13.0242 4.08889 13.0242 3.8963 12.9274 3.74815ZM6.49308 6.74074C5.08169 6.74074 3.93321 5.51111 3.93321 4C3.93321 2.48889 5.08169 1.25926 6.49308 1.25926C7.90447 1.25926 9.05295 2.48889 9.05295 4C9.05295 5.51111 7.90447 6.74074 6.49308 6.74074Z"
                                  fill="#8FBDE3"
                                />
                              </svg>
                              View
                            </Button>
                            <Button className="DownloadBtn" onClick={handleClickPopper('right', index)}>
                            <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5.12188 0.157278V2.88474H6.52143L4.00011 5.40556L1.47879 2.88474H2.87834V0H5.12179L5.12188 0.157278ZM1.08188 6.74348V5.59444H0V7.44718C0 7.75098 0.249036 8 0.552853 8H7.44715C7.75096 8 8 7.75098 8 7.44718V5.59444H6.91848V6.91851L1.08184 6.91816L1.08188 6.74348Z"
                                  fill="#05141F"
                                />
                              </svg>
                              PDF
                            </Button>
                            <Popper id={id} open={open[index]} anchorEl={anchorEl} transition placement={placement}>
                              {({ TransitionProps }) => (
                                  <Fade {...TransitionProps} timeout={350}>
                                      <Box
                                          sx={{
                                              border: '1px solid',
                                              borderColor: 'grey.300',
                                              boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                              p: 1,
                                              bgcolor: 'background.paper',
                                              borderRadius: 0,
                                              width: 300,
                                              height: 300,
                                              position: 'relative',
                                          }}
                                      >
                                      <button
                                          onClick={() => handleClickPopper(placement, index)(null)} // Pass null to close the popper
                                          style={{
                                              position: 'absolute',
                                              top: 5,
                                              right: 5,
                                              background: 'none',
                                              border: 'none',
                                              fontSize: '16px',
                                              cursor: 'pointer',
                                          }}
                                          >
                                          ✕
                                      </button>
                                          
                                      { row?.schematic_pdf.map((pdf) => {
                                          return (
                                              <Box key={pdf.files} className="text-center">
                                                  <Box className="p-relative">
                                                      <Tooltip title={pdf?.files} placement="top" className={` uploadedfilename`}>
                                                          <Typography>{'PDF'}</Typography>
                                                          <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_pdf', pdf?.files)} />
                                                          <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${pdf?.files}`)} />
                                                      </Tooltip>
                                                  </Box>
                                              </Box>
                                          )
                                      })
                                      }
               
                                      </Box>
                                  </Fade>
                              )}
                            </Popper>
                            {/* <Button
                              className="DownloadBtn"
                              sx={{ gap: "3px" }}
                              onClick={() =>
                                handleDownloadMultipleFile(
                                  new Date() + "schematic_pdf",
                                  row?.schematic_pdf
                                )
                              }
                            >
                              <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5.12188 0.157278V2.88474H6.52143L4.00011 5.40556L1.47879 2.88474H2.87834V0H5.12179L5.12188 0.157278ZM1.08188 6.74348V5.59444H0V7.44718C0 7.75098 0.249036 8 0.552853 8H7.44715C7.75096 8 8 7.75098 8 7.44718V5.59444H6.91848V6.91851L1.08184 6.91816L1.08188 6.74348Z"
                                  fill="#05141F"
                                />
                              </svg>
                              Pdf
                            </Button> */}
                            <Button className="DownloadBtn" onClick={handleClickPopperCad('right', index)}>
                              <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5.12188 0.157278V2.88474H6.52143L4.00011 5.40556L1.47879 2.88474H2.87834V0H5.12179L5.12188 0.157278ZM1.08188 6.74348V5.59444H0V7.44718C0 7.75098 0.249036 8 0.552853 8H7.44715C7.75096 8 8 7.75098 8 7.44718V5.59444H6.91848V6.91851L1.08184 6.91816L1.08188 6.74348Z"
                                  fill="#05141F"
                                />
                              </svg>
                              Cad
                            </Button>
                            <Popper id={idCAD} open={openCAD[index]} anchorEl={anchorElCAD} transition placement={placementCAD}>
                              {({ TransitionProps }) => (
                                  <Fade {...TransitionProps} timeout={350}>
                                      <Box
                                          sx={{
                                              border: '1px solid',
                                              borderColor: 'grey.300',
                                              boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                              p: 1,
                                              bgcolor: 'background.paper',
                                              borderRadius: 0,
                                              width: 300,
                                              height: 300,
                                              position: 'relative',
                                          }}
                                      >
                                      <button
                                          onClick={() => handleClickPopperCad(placement, index)(null)} // Pass null to close the popper
                                          style={{
                                              position: 'absolute',
                                              top: 5,
                                              right: 5,
                                              background: 'none',
                                              border: 'none',
                                              fontSize: '16px',
                                              cursor: 'pointer',
                                          }}
                                          >
                                          ✕
                                      </button>
                                          
                                      { row?.schematic_cad.map((pdf) => {
                                          return (
                                              <Box key={pdf.files} className="text-center">
                                                  <Box className="p-relative">
                                                      <Tooltip title={pdf?.files} placement="top" className={` uploadedfilename`}>
                                                          <Typography>{'CAD'}</Typography>
                                                          <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', pdf?.files)} />
                                                          <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${pdf?.files}`)} />
                                                      </Tooltip>
                                                  </Box>
                                              </Box>
                                          )
                                      })
                                      }
               
                                      </Box>
                                  </Fade>
                              )}
                            </Popper>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </>
                ) : (
                  <StyledTableCell align="right">No data found</StyledTableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
