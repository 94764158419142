import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Style from "../../styles/home.module.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import GroupIcon from '@mui/icons-material/Group';
import Person3Icon from '@mui/icons-material/Person3';
import CategoryIcon from '@mui/icons-material/Category';
import { useDispatch, useSelector } from "react-redux";
import { getGroupDealerApi } from "../../store/dealerGroup/getGroupDealer";
import { getGroupOutletAPI } from "../../store/dealerGroup/getGroupOutlet";
import { getKinUserApi } from "../../store/kinUser/getKinUser";
import { getInnoceansUserApi } from "../../store/innoceanUser/getInnoceanUser";
import { getSupplierUserApi } from "../../store/supplierUser/getSupplierUser";
export default function UserManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getGroupUser = useSelector((state) => state?.getGroupDealorSliceReducer?.result?.data);
  const groupOutletList = useSelector((state) => state?.getGroupOutletSliceReducer?.result);
  const getKinUser = useSelector((state) => state?.getKinUserSliceReducer?.result?.data);
  const getInnoceansUser = useSelector((state) => state?.getInnoceansUserSliceReducer?.result?.data);
  const getSupplierUser = useSelector((state) => state?.getSupplierUserSliceReducer?.result?.data);

  useEffect(() => {
    dispatch(getGroupDealerApi());
    dispatch(getGroupOutletAPI());
    dispatch(getKinUserApi());
    dispatch(getInnoceansUserApi());
    dispatch(getSupplierUserApi());
  }, []);


  return (
    <Box>
      <Box className={Style?.headerSection}>
        <Typography className={Style?.cardHeading} variant="h6">
          User Data
        </Typography>
      </Box>

      <Stack sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3} lg={3} style={{ display: "flex"}}>
            <Card className={Style?.homeCard5} onClick={() => navigate("/users/dealer")} style={{ cursor: "pointer", width: "100%" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                <GroupIcon/>

                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                    Dealer Group
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                    {getGroupUser?.length ? getGroupUser?.length : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} style={{ display: "flex"}}>
            <Card className={Style?.homeCard4} onClick={() => navigate("/users/dealer-outlet")} style={{ cursor: "pointer", width: "100%" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                <svg
                  width="20"
                  height="25"
                  viewBox="0 0 20 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 3.80005H0V24.8001H17V21.8001H20V0.800049H3V3.80005ZM16 23.8001H1V4.80005H3H12.5V8.30005H16V21.8001V23.8001ZM13.5 5.50705L15.293 7.30005H13.5V5.50705ZM4 1.80005H19V20.8001H17V7.59305L13.207 3.80005H4V1.80005Z"
                    fill="#05141F"
                  />
                  <path
                    d="M8.5 7.30029H2.5V13.3003H8.5V7.30029ZM7.5 12.3003H3.5V8.30029H7.5V12.3003Z"
                    fill="#05141F"
                  />
                  <path
                    d="M14.5 9.7998H10V10.7998H14.5V9.7998Z"
                    fill="#05141F"
                  />
                  <path
                    d="M14.5 12.2998H10V13.2998H14.5V12.2998Z"
                    fill="#05141F"
                  />
                  <path
                    d="M10 14.8003H2.5V15.8003H10V14.8003Z"
                    fill="#05141F"
                  />
                  <path
                    d="M14.5 14.8003H11.5V15.8003H14.5V14.8003Z"
                    fill="#05141F"
                  />
                  <path
                    d="M5.5 17.3003H2.5V18.3003H5.5V17.3003Z"
                    fill="#05141F"
                  />
                  <path
                    d="M14.5 17.3003H7V18.3003H14.5V17.3003Z"
                    fill="#05141F"
                  />
                  <path
                    d="M9.5 19.7998H2.5V20.7998H9.5V19.7998Z"
                    fill="#05141F"
                  />
                  <path
                    d="M14.5 19.7998H11V20.7998H14.5V19.7998Z"
                    fill="#05141F"
                  />
                </svg>

                <Stack>
                  <Typography variant="p"  className={Style.cardTitle}>
                    Dealer Outlet
                  </Typography>
                  <Typography variant="h5"  className={Style.cardValue}>
                    {groupOutletList?.length ? groupOutletList?.length : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} style={{ display: "flex"}}>
            <Card className={Style?.homeCard1} onClick={() => navigate("/users/kin")} style={{ cursor: "pointer", width: "100%" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                <Person3Icon/>

                <Stack>
                  <Typography variant="p" className={Style.cardTitle}>
                    KIA Users
                  </Typography>
                  <Typography variant="h5" className={Style.cardValue}>
                    {getKinUser?.length ? getKinUser?.length : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} style={{ display: "flex"}}>
            <Card className={Style?.homeCard2} onClick={() => navigate("/users/innoceans")} style={{ cursor: "pointer", width: "100%" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                 <Person3Icon/>
                <Stack>
                  <Typography variant="p" className={Style.cardTitle}>
                    Innocean Users
                  </Typography>
                  <Typography variant="h5" className={Style.cardValue}>
                    {getInnoceansUser?.length ? getInnoceansUser?.length : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} style={{ display: "flex"}}>
            <Card className={Style?.homeCard3} onClick={() => navigate("/users/suppliers")} style={{ cursor: "pointer", width: "100%" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
              <GroupIcon/>

                <Stack>
                  <Typography variant="p" className={Style.cardTitle}>
                    Supplier Users
                  </Typography>
                  <Typography variant="h5" className={Style.cardValue}>
                    {getSupplierUser?.length ? getSupplierUser?.length : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>

      {/* <Stack sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2">
              <Stack direction="row" spacing={1} className="card2Inner">
                <CheckCircleOutlineIcon />
                <Typography className="card2Content">All Groups</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2">
              <Stack direction="row" spacing={1} className="card2Inner">
                <CheckCircleOutlineIcon />
                <Typography className="card2Content">All Groups</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2">
              <Stack direction="row" spacing={1} className="card2Inner">
                <CheckCircleOutlineIcon />
                <Typography className="card2Content">All Groups</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2">
              <Stack direction="row" spacing={1} className="card2Inner">
                <CheckCircleOutlineIcon />
                <Typography className="card2Content">All Groups</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2">
              <Stack direction="row" spacing={1} className="card2Inner">
                <CheckCircleOutlineIcon />
                <Typography className="card2Content">All Groups</Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack> */}
    </Box>
  );
}
