import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Modal,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { sendFeedback } from "../../store/home/sendFeedback";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { showToast } from "../../toast/toast";
const useStyles = makeStyles((theme) => ({
  bgLightGray: {
    backgroundColor: "rgba(213, 222, 229, 1)",
  },
  borderRadius10: {
    borderRadius: "10px",
  },
  p10: {
    padding: "10px",
  },
  p20: {
    padding: "20px",
  },
  buttonsStyle: {
    backgroundColor: "#D7EDFE",
  },
  fullWidth: {
    width: "100%",
  },
}));

const Feedback = ({ open, handleClose ,feedBackOn , type, getData}) => {
  const formId = useSelector(
    (state) => state?.inputDrawingByClusterReducer?.result
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState("");
  // const formId = useSelector((state) => state?.inputDrawingReducer?.result?.results?.data[0]);

  const handleChange = (event) => {
    setFeedback(event.target.value);
  };
  const [loading, setLoading] = useState(false);
  const sendFeedbackSubmit = async () => {
    setLoading(true);
    if (feedback === "") {
      showToast(`Please Enter ${type}`,2);
      return;
    }
    const formData = new FormData();
    formData.append("form_id", formId?.data?.id);
    formData.append("user_type", "KIA");
    formData.append("feedback", feedback);
    formData.append("feedback_on", feedBackOn);
    if(feedBackOn === "Schematic Layout"){
      formData.append("added_for", 'INN');
    }else{
      formData.append("added_for", 'Dealer');
    }
    

    await dispatch(sendFeedback(formData))
      .then((data) => {
        getData();
        showToast(data?.payload?.message, 1);
        setFeedback("");
        setLoading(false);
        handleClose();
      })

      .catch((error) => {
        setLoading(false);
        console.error("Error editing email template:", error);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <CardHeader
            sx={{ textAlign: "center" }}
            action={
              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            }
            title={`Raise a ${type}`}
          />
          <Box className="mx-auto" style={{ maxWidth: "500px", width: "100%" }}>
            <CardContent className="d-flex" style={{
                  width: "100%",paddingInline: "0"}}>
              <TextareaAutosize
                style={{
                  width: "100%",
                  border: "1px solid #C4D3DF",
                  borderRadius: "5px",
                  padding: "10px",
                }}
                className="bg-white mx-auto"
                minRows={6}
                placeholder={`Enter your  ${type}`}
                value={feedback}
                onChange={handleChange}
              />
            </CardContent>
            <CardActions disableSpacing  style={{
                  width: "100%",paddingInline: "0"}}>
              <Box
                component={"div"}
                width={"100%"}
                marginBlockStart={0.5}
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                gap={2}
              >
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    color: "rgba(167, 177, 187, 1)",
                    fontSize: "20px",
                    fontWeight: 600,
                    textDecoration: "underline",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => sendFeedbackSubmit()}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: 1.8,
                    boxShadow: "none",
                    backgroundColor: "#000 !important",
                    
                  }}
                  className={classes.buttonsStyle}
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send"}
                </Button>
              </Box>
            </CardActions>
          </Box>
        </Card>
      </Modal>
    </>
  );
};

export default Feedback;
