import React, { useEffect, useState } from 'react';
import {  Button,   Grid, InputAdornment, Stack,  TextField,  Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import Body from '../../../components/Body';
import meter from "../../../assets/img/meter.png";
import { showToast } from '../../../toast/toast';
import AddInventory from '../../reusable/AddInventoryModal';
import DeleteConfirmationModal from '../../reusable/DeleteConfirmationModal';
import DataTable from '../../reusable/DataTable';
import { getCategoryApi } from '../../../store/category/getCategory';
import { deleteInventoryApi, getInventoryApi } from '../../../store/inventory';
import { getOrganizationApi } from '../../../store/organization/getOrganization';
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import InventoryIcon from '@mui/icons-material/Inventory';


const Inventory = () => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [rowsData, setRowsData] =useState([]);
    const [editRowData, setEditRowData] = useState({});
    const [openInventoryModal, setOpenInventoryModal] = useState(false);
    const {result:inventoryList}  = useSelector(state => state?.inventoryReducer);

    const columns = [
        { field: "supplier_company_name", headerName: "Sub-Vendor/Supplier", width: 250},  
        { field: "category_name", headerName: "Category", width: 150},
        {
            field: 'snap',
            headerName: 'Image',
            width: 150,
            renderCell: (params) => (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                {params.value ? (
                  <img
                    src={params.value}
                    alt="snap"
                    style={{ maxWidth: '52px', maxHeight: '52px',height:"52px",width:"52px" }}
                  />
                ) : (
                  <span>No Image</span>
                )}
              </div>
            ),
        },
        { field: "kia_specification", headerName: "Vendor Specification", width: 250},
        { field: "unit_of_measurement", headerName: "Unit", width: 150}, 
        { field: "vendor_price", headerName: "Dealer Price", type: 'number',sortComparator: (v1, v2) => Number(v1) - Number(v2),width: 150, headerAlign: "left", cellClassName:"justify-start"},
        { field: "supplier_price", headerName: "Supplier Price", width: 150, type: 'number', sortComparator: (v1, v2) => Number(v1) - Number(v2), headerAlign: "left", cellClassName:"justify-start"},
        { field: "quantity", headerName: "Quantity", width: 100, type: 'number', sortComparator: (v1, v2) => Number(v1) - Number(v2), headerAlign: "left", cellClassName:"justify-start"},
    ];

    const editUser = (id) => {
        const dataToEdit = rowsData.find((item) => item.id === id);
        setEditRowData(dataToEdit)
        setOpenInventoryModal(true)
    };

    const deleteInventory = (deleteRowId) => {
        setOpenModal(true);
        setDeleteId(deleteRowId)
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    };
  

    const handleConfirmDelete = async() => {
        const dataTodelete = {
            id: deleteId
        }
        const deleteData = await dispatch(deleteInventoryApi(dataTodelete));
        if(deleteData?.error){
          const { message } = deleteData?.payload;
        }else{
          showToast(deleteData?.payload?.message, 1);
          dispatch(getInventoryApi());
          setDeleteId(null)
          handleCloseModal()
        }
    };

    const [searchText, setSearchText] = useState();
    const handleChangeSearch = (e) => {
        e.preventDefault();
        const searchTerm = e.target.value.toLowerCase(); 
        setSearchText(searchTerm);
    };
    const searchData = (searchText) => {
        if (!searchText) {
            const updatedArray = inventoryList?.data?.map(obj => ({
                ...obj,
                snap: obj.snap && obj.snap ? `${process.env.REACT_APP_BASE_URL}${obj.snap}` : null,
              }));
            setRowsData(updatedArray);
          return;
        }
        const filteredData = inventoryList?.data?.filter((row) => {
            const supplier_company_name = (row.supplier_company_name || "").toLowerCase();
            const name = (row.category_name || "").toLowerCase();
            return (
              supplier_company_name.includes(searchText) ||
              name.includes(searchText)
            );
          }).map(obj => ({
            ...obj,
            snap: obj.snap && obj.snap ? `${process.env.REACT_APP_BASE_URL}${obj.snap}` : null,
          }));
          
          setRowsData(filteredData);
    }

    const closePopUp = () => {
        setEditRowData({});
        setOpenInventoryModal(false);
    }

    useEffect(() => {
        searchData(searchText);
    },[ searchText]);
    useEffect(() => {
        dispatch(getOrganizationApi())
        dispatch(getCategoryApi())
        dispatch(getInventoryApi())
      },[dispatch])

    useEffect(() => {
    if (inventoryList) {
        const updatedArray = inventoryList?.data?.map(obj => ({
            ...obj,
            snap: obj.snap && obj.snap ? `${process.env.REACT_APP_BASE_URL}${obj.snap}` : null,
            }));
        setRowsData(updatedArray);
    }
    }, [inventoryList]);

    return (
        <Body>
            <Grid container alignItems="center" flexWrap={"wrap"} spacing={1}>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                    <Stack direction="row" spacing={1}  sx={{ my: 2 }} alignItems="center">
                        {/* <img src={meter} alt="Meter" /> */}
                        <InventoryIcon/>
                        <Typography variant="h6" className="pageTitle">Inventory</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={8}>
                    <Grid container flexWrap={"nowrap"} spacing={1} justifyContent={"end"}>
                        <Grid item xs={12} sm={12} md={6} lg={6}  sx={{ marginRight: {md: 0.5,sm: 0,}, }}>
                            <TextField fullWidth size="small" 
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                          <InputAdornment position="end" onClick={() => setSearchText("")}>
                                              { searchText?.length > 0 ? <ClearIcon style={{cursor:"pointer"}} /> : ""}
                                          </InputAdornment>
                                      ),
                                }}
                                placeholder="Search by Vendor or Category" 
                                value={searchText}
                                onChange={handleChangeSearch}
                            />
                        </Grid>
                        <Grid item sm="auto">
                            <Button variant="contained" onClick={()=>setOpenInventoryModal(true)}>
                                Add Inventory
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sx={{ overflowX: "auto", widt: "100%" }}>
                    <DataTable
                        data={rowsData ? rowsData : []}
                        columns={columns}
                        deleteUser={deleteInventory}
                        editUser={editUser}
                        type='inventory'
                    />
                </Grid>
            </Grid>
            <AddInventory open={openInventoryModal} handleClose={closePopUp} editRowData={editRowData}/>
            <DeleteConfirmationModal open={openModal} handleClose={handleCloseModal}  handleConfirm={handleConfirmDelete}  deleteType='Inventory'/>
        </Body>
        
    );
}
export default Inventory
