import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Style from "../../styles/home.module.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import layoutIconSvg from "../../assets/img/layout-svg.png";
import { ordersApi } from "../../store/orders/orders";
import { useDispatch, useSelector } from "react-redux";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import {  orderCountApi } from "../../store/home/countReducers";

export default function OrdersDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loading:orderLoading, error:orderError, result:orderList}  = useSelector(state => state?.orders);
  const countData = useSelector((state) => state?.countReducer?.orderCountApi?.result);

  const handleNavigate = (url) => {
    navigate(`/${url}`);
  }
  useEffect(() => {
    dispatch(ordersApi())
    dispatch(orderCountApi())
},[dispatch])
  return (
    <Box>
      <Box className={Style?.headerSection}>
        <Typography className={Style?.cardHeading} variant="h6">
          Orders
        </Typography>
      </Box>

      <Stack sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard3} onClick={() => handleNavigate('orders')} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                <WidgetsOutlinedIcon/>
                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                  Total Orders
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                  {orderList?.data?.length ? orderList?.data?.length : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard1} onClick={() => handleNavigate('orders')} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                <WidgetsOutlinedIcon/>
                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                  Pending Orders
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                  {countData?.order_underprocess ? countData?.order_underprocess : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard2} onClick={() => handleNavigate('orders')} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                <WidgetsOutlinedIcon/>
                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                  Completed Orders
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                  {countData?.completed_order ? countData?.completed_order : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard4} onClick={() => handleNavigate('orders')} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                <WidgetsOutlinedIcon/>
                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                  Cancelled Orders
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                  {countData?.cancelled_order ? countData?.cancelled_order : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard5} onClick={() => handleNavigate('orders')} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                <WidgetsOutlinedIcon/>
                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                  Refunded Orders
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                  {countData?.refunded_order ? countData?.refunded_order : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          
        </Grid>
      </Stack>
    </Box>
  );
}
