import { Box } from "@mui/material";
import { formatDateString } from "./dateFormater";

export const isAllOrderValidationTrue = (orderItems) => {
  // Ensure orderItems is an array before proceeding
  if (!Array.isArray(orderItems)) {
    console.error("orderItems is not an array:", orderItems);
    return false;
  }

  for (let item of orderItems) {
    if (!item.order_validation) {
      return false;
    }
  }
  return true;
}


export const  isAllOrderAcceptedTrue = (orderItems) => {
  if (!Array.isArray(orderItems)) {
    console.error("orderItems is not an array:", orderItems);
    return false;
  }


  for (let item of orderItems) {
    if (!item.order_accepted) {
      return false;
    }
  }
  return true;
}

export const createHtmlByArrayElement = (arr) => {
  console.log(arr,"arr")
  return;
  // if (arr.length === 0) {
  //   return null; // Return null if the array is empty
  // }
  // const newD = arr[arr.length-1];
  return <>
    <tr>
        <td className='' style={{width:"300px"}}>
            <Box className="w-100 pt-16 pb-16">
                <Box className="d-flex w-100">
                    <Box className="fw-600" sx={{mr:"10px"}}>
                        Transportation No.:
                    </Box>
                    <Box className="fw-400">
                    {arr?.transportation_number ? arr?.transportation_number : "N/A"}
                    </Box>
                </Box>

            </Box>
        </td>
        <td className=''>
            <Box className="w-100 pt-16 pb-16">
                <Box className="d-flex w-100">
                    <Box className="fw-600"  sx={{mr:"10px"}}>
                        Date:
                    </Box>
                    <Box className="no-border fw-400">
                      {arr?.date ? formatDateString(arr?.date) : "N/A"}
                
                    </Box>
                </Box>
            </Box>
        </td>
    </tr>
    <tr>
        <td className='' style={{width:"200px"}}>
            <Box className="w-100 pt-16 pb-16">
    
                <Box className="d-flex w-100">
                    <Box className="fw-600" sx={{mr:"10px"}}>
                    Delivery Person Name:
                    </Box>
                    <Box className="fw-400">
                     {arr?.delivery_person_name ? arr?.delivery_person_name : "N/A"}
                    </Box>
                </Box>
            </Box>
        </td>
        <td className=''>
            <Box className="w-100 pt-16 pb-16">
                <Box className="d-flex w-100">
                    <Box className="fw-600" sx={{mr:"10px"}}>
                    Delivery Contact No.:
                    </Box>
                    <Box className="no-border fw-400">
                    {arr?.delivery_contact_number ? arr?.delivery_contact_number : "N/A"}
                    </Box>
                </Box>
            </Box>
        </td>
    </tr>
  </>
};